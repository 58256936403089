import request from '@/utils/request'

// 品类下拉数据
export function classList(data) {
  return request({
    url: '/baseAdmin/common/goods-class-option',
    method: 'post',
    data,
  })
}

// 品牌下拉数据
export function brandList(data) {
  return request({
    url: '/baseAdmin/common/goods-brand-option',
    method: 'post',
    data,
  })
}

// 区域下拉树
export function saleAreaList(data) {
  return request({
    url: '/baseAdmin/common/area-option',
    method: 'post',
    data,
  })
}

// 商品列表下拉 通路 是否有图 分组		下拉
export function goodsDetailList(data) {
  return request({
    url: '/baseAdmin/goods/option',
    method: 'post',
    data,
  })
}
